import createStore from "../../../../../AppCustomStateManagement/createStore";
import { useActionScreenManagerStore } from "../../../../CustomElements/ProductionUI/ProductionOperationConfirmation/ActionScreensManager/ActionScreenManagerStore";

// Library interfaces
interface IProductionOrderSelection {
  productionOrder?: any;
  productionOrder_product_completePct?: any;
  productionOrderProduct_ParameterizedVariableValues?: any;
  product?: any;
  currentOperation?: any;
  nextOperation?: any;
  production?: any;
  productionRecord?: any;
  isCurrentOperationTheLast?: any;
  currentOperationActionIndex: number;
  skipBackEndTypeAction: boolean;
  prTempStore?:any;
  productionOrderProduct?:any;
}

interface IProductionOrderAction {
  type: any;
  payload?: any;
}

const initialState = {
  productionOrder: {},
  productionOrder_product_completePct: {},
  productionOrderProduct_ParameterizedVariableValues: {},
  product: {},
  production: {},
  productionRecord: {},
  currentOperation: {},
  currentOperationActionIndex: 0,
  nextOperation: {},
  isCurrentOperationTheLast: false,
  skipBackEndTypeAction: false,
  prTempStore:[],
  productionOrderProduct:{},
  popSpaceDriveFiles:[],
  stagedMaterial:[]
};

const reducer = (
  store: IProductionOrderSelection,
  action: IProductionOrderAction
) => {
  switch (action.type) {
    case "SET_PRODUCTION_ORDER":
      store.productionOrder = action.payload;
      return store;
    case "SET_STORE":
      store = action.payload;
      return store;
    case "RESET_ACTION_INDEX":
      store = { ...store, currentOperationActionIndex: 0 };
      return store;
    case "RESET_STORE":
      store = initialState;
      return store;
    case "RESET_CURRENT_OPERATION":
      store = { ...store, currentOperation: initialState.currentOperation };
      return store;
    case "RESET_CURRENT_PRODUCTION":
      store = { ...store, production: initialState.production };
      console.log("RESETTING current production");
      return store;
    case "SET_PRODUCTION":
      store = { ...store, production: action.payload };
      return store;
    case "SET_PRODUCTION_RECORD":

      store = { ...store, productionRecord: action.payload };
      return store;
    case "SET_PRODUCTION_ORDER_PRODUCT":

      store = { ...store, productionOrderProduct: action.payload };
      return store;
    case "SET_CURRENT_OPERATION":
      store = { ...store, currentOperation: action.payload };
      return store;
    case "SET_PR_TEMP_STORE":


      store = { ...store, prTempStore: action.payload };
      return store;
    case "NEXT_OPERATION_ACTION":
      let curActionIndex = store.currentOperationActionIndex;
      store = { ...store, currentOperationActionIndex: curActionIndex + 1 };
      return store;
    case "SKIP_BACKEND_TYPE_OPERATION":
      store = { ...store, skipBackEndTypeAction: true };
      return store;
    case "UNSET_SKIP_BACKEND_TYPE_ACTION":
      store = { ...store, skipBackEndTypeAction: false };
      return store;
    default:
      return store;
  }
};

export const [
  useProductionOrderSelectionStore,
  dispatchProductionOrderSelectionStore,
] = createStore(initialState, reducer);

interface IPrStore {
  Id:any, 
  Id_OperationProduct:any, 
  Id_OperationProduct_Action:any,
  hashEdit: any
}

export const useManageProductionOrderSelectionStore = () => {

  const poState = useProductionOrderSelectionStore();
  const currentActionScreenManager = useActionScreenManagerStore();

  const updateHashEditPrTempStoreById = (idPrStore:IPrStore, newHashEdit:any) => {
    let newState = {...poState};
    let thePrStore = newState?.prTempStore ? newState?.prTempStore: [];

    let currentIdOperation = newState.currentOperation.Id;
    //let currentIdProductionRecord = newState.productionRecord.Id;
    let currActConfig = currentActionScreenManager?.currentActionConfig;
    let idOperationProductAction = currActConfig?.Id_OperationProduct_Action;

    let found = false;
    for(let i = 0; i<thePrStore.length; i++){
      if(thePrStore[i].Id === idPrStore){
        thePrStore[i].hashEdit = newHashEdit;
        found = true;
        break;
      }
    }
    if(!found){
      thePrStore.push({
        Id:idPrStore, 
        Id_OperationProduct:currentIdOperation, 
        Id_OperationProduct_Action:idOperationProductAction,
        hashEdit: newHashEdit
      });
    }
   
  
    if(thePrStore){
      dispatchProductionOrderSelectionStore({
        type:"SET_PR_TEMP_STORE",
        payload:JSON.parse(JSON.stringify(thePrStore))
      });
    }

  };

  const getHashEditByPrTempStoreId = (idPrTempStore:any) => {
    let newState = {...poState};
    let thePrStore = newState?.prTempStore;
    if(newState?.prTempStore){
      thePrStore = newState.prTempStore;
      for(let i = 0; i<thePrStore.length; i++){
        if(thePrStore[i].Id === idPrTempStore){
          return thePrStore[i].hashEdit;
         
        }
      }
    }
    return null;
  }

  const getHashEditByCurrentSelectionOperation = () => {
    let newState = {...poState};

    let currentIdOperation = newState.currentOperation.Id;
    //let currentIdProductionRecord = newState.productionRecord.Id;
    let currActConfig = currentActionScreenManager?.currentActionConfig;
    let idOperationProductAction = currActConfig?.Id_OperationProduct_Action;

    let thePrStore = newState?.prTempStore;
    if(newState?.prTempStore){
      thePrStore = newState.prTempStore;
      for(let i = 0; i<thePrStore.length; i++){
        //hePrStore[i].Id_ProductionRecord === currentIdProductionRecord && 
        //commented because if it is a collab work the pr is not the same and also its already filtered by pr from the first fetch
        if(thePrStore[i].Id_OperationProduct === currentIdOperation && thePrStore[i].Id_OperationProduct_Action === idOperationProductAction){
          return thePrStore[i].hashEdit;
         
        }
      }
    }
    return null;
  }

  return { getHashEditByCurrentSelectionOperation,updateHashEditPrTempStoreById,getHashEditByPrTempStoreId };
};
