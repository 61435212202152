import { useCallback, useEffect, useRef, useState } from "react";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import HxfDigitKeyboard from "../../../../HxfDigitKeyboard/HxfDigitKeyboard";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionScreenAskProducedProductIrregularity.module.scss";
import ActionScreenAskProducedProductIrregularityConfirmationButtons from "./ActionScreenAskProducedProductIrregularityConfirmationButtons";
import {
  dispatchActionScreenAskProducedProductIrregularityStore,
  useActionScreenAskProducedProductIrregularityStore,
} from "./ActionScreenAskProducedProductIrregularityStore";
import DeleteIcon from '@mui/icons-material/Delete';
import ActionScreenIrregularityElement from "./ActionScreenIrregularityElement";
import { IonIcon } from "@ionic/react";
import { IonButton } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import ActionScreenIrregularityDefineWastes, {
  subscreenDefineWastesBuildArrayProductsFromBom,
} from "./ActionScreenIrregularityDefineWastes/SubScreenDefineWastes";
import SubScreenDefineWastesConfirmationButtons from "./ActionScreenIrregularityDefineWastes/SubScreenDefineWastesConfirmationButtons/SubScreenDefineWastesConfirmationButtons";
import { IProduct } from "../../../../../../barrel/modelInterfaces/iProduct";
import useProductivityScale from "../../../../../../barrel/hooks/useProductivityScale";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import { Trans } from "react-i18next";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import { useGlobalState } from "../../../../../../GlobalCustomStateManagement/GlobalStateProvider";

interface IResult {
  irregularitysList: any;
}

export interface IWasteProduct extends IProduct {
  Quantity?: any;
  locationData?:any;
}
export interface IMapWastes {
  [keyWasteProduct: string]: IWasteProduct;
}

export function buildWasteKey(wasteProduct: IProduct) {
  let key = "";
  key = "" + wasteProduct.Id_Product;
  if (wasteProduct.productParameterizedVariablesData.length > 0) {
    for (
      let i = 0;
      i < wasteProduct.productParameterizedVariablesData.length;
      i++
    ) {
      let idppv =
        wasteProduct.productParameterizedVariablesData[i]
          .Id_Product_ParameterizedVariable;
      let val = wasteProduct.productParameterizedVariablesData[i].Value;
      key = key + "_" + idppv + "_" + val;
    }
  }

  return key;
}

function convertMapWastesToArrayProductsData(mapWastes: IMapWastes) {
  let keys = Object.keys(mapWastes);
  let arrayProducts = [] as IWasteProduct[];
  for (let i = 0; i < keys.length; i++) {
    let wasteProduct: IWasteProduct = mapWastes[keys[i]];
    arrayProducts.push(wasteProduct);
  }

  return arrayProducts;
}



function ActionScreenAskProducedProductIrregularity(props: any) {
  const [latestClickedIrregularityIndex, setLatestClickedIrregularityIndex] =
    useState(0);
  const [indexkey, setIndexkey] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);
  const { sessionState } = useGlobalState();
  const productivityScale = useProductivityScale();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const screenState = useActionScreenAskProducedProductIrregularityStore();
  console.log("CURRENT SCREENSTATE: ",screenState);
  const feedbackService = useFeedbackService();
  const {t} = useHxfTranslation();
  const currentActionScreenManager = useActionScreenManagerStore();
  const [currentStep, setCurrentStep] = useState("SELECT_IRREGULARITY"); //CONFIRM_QTY,   If Results in Waste  DEFINE_WASTES

  const [addingIrregularityData, setAddingIrregularityData] = useState<any>({
    temp_name: "",
    temp_code: "",
    Id_Irregularity: 0,
    filledIrregularityData: {},
  });

  const [result, setResult] = useState<IResult>({ irregularitysList: [] });

  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);

  const qtOcurrencesSetMultiplierBOM = useRef(0);
  const qtOcurrencesSetMultiplierSelfProduct = useRef(0);

  // states to allow clearing when loading a default stock location config

  const [remountKeypadIndex, setRemountKeypadIndex] = useState(0);

  const forceRerender = useCallback(() => {
    setIndexkey(indexkey + 1);
  }, [indexkey]);


  function getLimitToWorkingQtyIds(){
    let irregularityIds = [];
    //get all irregularity ids of type "defective"
    let allIrregularities = currentActionScreenManager?.currentActionConfig?.irregularitysList;
    if(allIrregularities){
      for(let i = 0; i<allIrregularities.length; i++){
        if(allIrregularities[i].limitToWorkingQty === 1){
          irregularityIds.push(allIrregularities[i].Id_Irregularity);
        }
      }
    }

    return irregularityIds;
  }

  function getMaximumOccurrencesAllowedLimitToWorkingQty(){

    let maximumAllowed = 0;
    let definedProducingProductQty = 0;
    let postingData = props?.productionOperationHandler?.getCurrentPostingProductionDataObject();
  
    //check produced product first
    if(postingData?.PRODUCE_PRODUCT_QUANTITY?.quantity){
      definedProducingProductQty = postingData?.PRODUCE_PRODUCT_QUANTITY?.quantity;
      maximumAllowed = definedProducingProductQty;
    }

    //check productivity scale
    if(maximumAllowed === 0){
      maximumAllowed = productivityScale.getProductivityScale(); 
    }
  
    //get all irregularity ids of type "limitToWorkingQty"
    let irregularityIds = getLimitToWorkingQtyIds();


    //now subtract by already defined limitToWorkingQty
    
    let addedIrregularities = screenState.addedIrregularitys;
    if(addedIrregularities){
      for(let i = 0; i<addedIrregularities.length; i++){
        if(irregularityIds.includes(addedIrregularities[i].Id_Irregularity)){
          let qtyFilled = addedIrregularities[i]?.filledIrregularityData?.quantity;
          if(qtyFilled && qtyFilled > 0){
            maximumAllowed -= qtyFilled;
          }
     
        }
      }
    }
   

    return maximumAllowed;
  }


  function confirmIrregularitysList() {
    let currentAddedIrregularitys = JSON.parse(JSON.stringify([...screenState.addedIrregularitys])); //make real clone to avoid issues changing state
    let result = currentAddedIrregularitys;

    for (let i = 0; i < result.length; i++) {
      delete result[i].temp_name;
      delete result[i].temp_code;
    }
    return result;
  }
  const confirmQuantity = useCallback(
    (res: any) => {
      if (isNaN(res) || res == "") {
        setShowErrorInput(true);
        forceRerender();
        return false;
      }
      let valueNumber = parseFloat(res) + 0;

      let allowZeroQuantity = true;
      if (allowZeroQuantity && valueNumber === 0) {
        let newResult = { ...result, quantity: 0 };
        setResult(newResult);
        return newResult;
      } else if (valueNumber === 0 && !allowZeroQuantity) {
        setShowErrorInput(true);
        forceRerender();
        return false;
      } else {
        //let returnObj = {quantity:valueNumber};
        let newResult = { ...result, quantity: valueNumber };
        setResult(newResult);
        return newResult;
        //props.callbackSuccessConfirm(returnObj);
      }

      return false;
    },
    [forceRerender, result]
  );

  /**
   * sets the current map of wastes to the product bom x occurrences
   */
  const buildMapWastesFromProductBom = useCallback(
    (occurrencesQt: number) => {
      let productBom = productionOrderSelectionStore?.product?.product_BoM;

      let opts:any = {};
      if(addingIrregularityData?.fullIrregularityObject?.resWasteBom_filter_Id_ProductFamily){
        opts.allowedProductFamiliesIds = [parseInt(addingIrregularityData.fullIrregularityObject.resWasteBom_filter_Id_ProductFamily)];
      }
      
      let arrayProductsComponents = subscreenDefineWastesBuildArrayProductsFromBom(opts,addingIrregularityData.fullIrregularityObject,productBom,sessionState);
      let newMapWastes: any = [];
      for (let i = 0; i < arrayProductsComponents.length; i++) {
        let productWaste = arrayProductsComponents[i];
        let newQuantity = productWaste?.Quantity
          ? productWaste.Quantity * occurrencesQt
          : 0;
        let mapKey = buildWasteKey(productWaste);
        productWaste.Quantity = newQuantity;

        newMapWastes[mapKey] = productWaste;
      }
      return newMapWastes;
    },
    [
      productionOrderSelectionStore,
      setAddingIrregularityData,
      addingIrregularityData,
    ]
  );
  const getCurrentMapWastes = useCallback(() => {
    if (addingIrregularityData.filledIrregularityData?.mapWastes) {
      return addingIrregularityData.filledIrregularityData?.mapWastes;
    }

    let newWastesMap:any = {};
    //if consume frombom

    let wastesFromBom = buildMapWastesFromProductBom(qtOcurrencesSetMultiplierBOM.current);
    newWastesMap = {...newWastesMap, ...wastesFromBom};
    let shouldConsumeSelfProduct = addingIrregularityData?.fullIrregularityObject?.resultingWaste_selfProduct === 1;
   
 
    if(shouldConsumeSelfProduct){

      let selfProductToWaste:IProduct = {
        Id_Product:productionOrderSelectionStore?.product?.Id,
        productParameterizedVariablesData:productionOrderSelectionStore.productionOrderProduct_ParameterizedVariableValues,
        _visuallyHideFromWastes:true,
        Quantity:qtOcurrencesSetMultiplierSelfProduct.current
      }
      let selfProductWasteKey = buildWasteKey(selfProductToWaste);

      if(newWastesMap?.[selfProductWasteKey]){
        newWastesMap[selfProductWasteKey].Quantity += qtOcurrencesSetMultiplierSelfProduct.current;
      }else{
        newWastesMap[selfProductWasteKey] = selfProductToWaste;
      }
    }
   
    setAddingIrregularityData({
      ...addingIrregularityData,
      filledIrregularityData: {
        ...addingIrregularityData.filledIrregularityData,
        mapWastes: { ...newWastesMap },
      },
    });

    return newWastesMap;
  }, [buildMapWastesFromProductBom, addingIrregularityData]);

  const confirmClick = () => {
    let storeResult = confirmIrregularitysList();
    props.callbackSuccessConfirm(storeResult);
  };

  const hasOnlyOneIrregularity = useCallback(() => {
    let irregularitys =
      currentActionScreenManager?.currentActionConfig?.irregularitysList;
    if (!irregularitys) {
      throw "No Irregularitys defined for this action.";
    }
    if (irregularitys.length === 1) {
      return true;
    }
    return false;
  }, [currentActionScreenManager?.currentActionConfig?.irregularitysList]);

  const finishIrregularity = useCallback(
    (addingIrregularityObj: any) => {
      let currentAddedIrregularitys = screenState.addedIrregularitys;
      currentAddedIrregularitys.push(addingIrregularityObj);
  
      dispatchActionScreenAskProducedProductIrregularityStore({
        type: "SET_ADDED_IRREGULARITIES",
        payload: currentAddedIrregularitys,
      });
    },
    [screenState.addedIrregularitys]
  );

  const confirmWastesDialog = useCallback(
    (addingIrregularityDataObject) => {
      let resWasteBom_requireAtLeastOnePositiveWaste = addingIrregularityDataObject?.fullIrregularityObject?.resWasteBom_requireAtLeastOnePositiveWaste === 1;
     
      let resWasteBom_askWasteLoc = addingIrregularityDataObject?.fullIrregularityObject?.resWasteBom_askWasteLoc === 1;
      let requireFilledLocation = resWasteBom_askWasteLoc === true;
      let foundAtLeastOnePositiveWaste = false;
      let currentMapWastes: IMapWastes | any = getCurrentMapWastes();
      if (currentMapWastes) {
        let keys = Object.keys(currentMapWastes);
        for (let i = 0; i < keys.length; i++) {
          let productQty = currentMapWastes[keys[i]].Quantity + ""; //convert to string
          let firstChar = productQty.charAt(0);
          let lastChar = productQty.charAt(productQty.length - 1);
          if (firstChar === ".") {
            productQty = "" + "0" + productQty;
          }
          if (lastChar === ".") {
            productQty = "" + productQty + "0";
          }

          currentMapWastes[keys[i]].Quantity = parseFloat(productQty);

          let locationData = currentMapWastes[keys[i]]?.locationData;

          if(parseFloat(productQty) > 0){
            foundAtLeastOnePositiveWaste = true;
            if(requireFilledLocation){
              if(!locationData?.Id_Lot){
                feedbackService.notifyToast(t('invalid.wasting.location'),"error");
                return;
              }
            }

          }
        }

        addingIrregularityDataObject.filledIrregularityData.mapWastes = {
          ...currentMapWastes,
        };
      } else {
        //currentMap = {};
        addingIrregularityDataObject.filledIrregularityData.mapWastes = {
          ...currentMapWastes,
        };
      }

      if(!foundAtLeastOnePositiveWaste && resWasteBom_requireAtLeastOnePositiveWaste){
          feedbackService.notifyToast(t('please.add.a.waste.to.confirm.this.irregularity'),"error");
        return;
      }
      //fix submap chain null
      let stringAddingObj = JSON.stringify(addingIrregularityDataObject);
      let addingIrregularityJsonObj = JSON.parse(stringAddingObj);
      //---
      if(addingIrregularityJsonObj?.fullIrregularityObject){
        //clear from posting obj
        delete addingIrregularityJsonObj.fullIrregularityObject;
      }
      finishIrregularity(addingIrregularityJsonObj);
      setCurrentStep("SELECT_IRREGULARITY");
    },
    [getCurrentMapWastes, setCurrentStep, finishIrregularity]
  );

  const handleStoreResult = useCallback(
    (res: any, customIrregularityData: any = null) => {
      
      let storeResult = confirmQuantity(res);

      if (storeResult !== false) {
        //
        let hasWastesToDefine = false;
        let hasSelfWaste = false;
        let addingIrregularityObj = null;
        if (storeResult.quantity > 0) {
          let loadedIrregularityData: any = {};
          if (!customIrregularityData) {
            loadedIrregularityData = { ...addingIrregularityData };
          } else {
            loadedIrregularityData = customIrregularityData;
          }

          addingIrregularityObj = {
            ...loadedIrregularityData,
            filledIrregularityData: {
              ...addingIrregularityData.filledIrregularityData,
              quantity: storeResult.quantity,
            },
          };
          
          if(loadedIrregularityData?.fullIrregularityObject?.resultingWaste === 1){
            hasWastesToDefine = true;
          }
          if(loadedIrregularityData?.fullIrregularityObject?.resultingWaste_selfProduct === 1){
            hasSelfWaste = true;
          }
          if (
            hasWastesToDefine || hasSelfWaste
          ) {
            setAddingIrregularityData(addingIrregularityObj);
          } else {
            finishIrregularity(addingIrregularityObj);
          }
        }

//qtOcurrencesSetMultiplierSelfProduct

        if (hasWastesToDefine) {
          if (
            customIrregularityData?.fullIrregularityObject
              ?.resultingWaste_calculateFromBomAndOccurrences
          ) {
            qtOcurrencesSetMultiplierBOM.current = res;
          } else {
            qtOcurrencesSetMultiplierBOM.current = 0;
          }
        }

        if(hasSelfWaste){
          qtOcurrencesSetMultiplierSelfProduct.current = res;
        }else{
          qtOcurrencesSetMultiplierSelfProduct.current = 0;
        }
        if (hasWastesToDefine || hasSelfWaste) {
          //check if has at least one product to define wastes
          let builtArray: any = [];
          if(hasWastesToDefine){
            builtArray = subscreenDefineWastesBuildArrayProductsFromBom(
              {},
              customIrregularityData?.fullIrregularityObject,
              productionOrderSelectionStore?.product?.product_BoM,
              sessionState
            );
          }

          let countProductsToDefineWaste = 0;
          if(builtArray && builtArray.length > 0){
            for(let i = 0; i<builtArray.length; i++){
              if(!builtArray[i]?._visuallyHideFromWastes){
                countProductsToDefineWaste++;
              }
            }
          }


          let hasAtLeastOneProductToDefinedWastes = countProductsToDefineWaste > 0;
          //if doesnt have at least one product to define wastes, auto skip

          if (
           !hasAtLeastOneProductToDefinedWastes || customIrregularityData?.fullIrregularityObject?.resultingWaste_calculateFromBomAndOccurrences_noConfirmation === 1
          ) {
            
            confirmWastesDialog(addingIrregularityObj);
          } else {
            setCurrentStep("DEFINE_WASTES");
          }
        } else {
          setCurrentStep("SELECT_IRREGULARITY");
        }
      }
    },
    [
      addingIrregularityData,
      confirmQuantity,
      finishIrregularity,
      confirmWastesDialog,
    ]
  );

  const checkIfIsEnabled = (obj:any,index:any) => {

    if(obj.limitToWorkingQty === 1){

      let isResultingWaste = obj?.resultingWaste === 1;
      //get all irregularity ids of type "defective"
      let irregularityIdsDefect = getLimitToWorkingQtyIds();


      //now check if already added one of this type
  
      let existingIrregularities = currentActionScreenManager?.currentActionConfig?.irregularitysList;
      let addedIrregularities = screenState.addedIrregularitys;
      if(addedIrregularities){
        for(let i = 0; i<addedIrregularities.length; i++){
          if(irregularityIdsDefect.includes(addedIrregularities[i].Id_Irregularity)){
            //is defect
            
            //check if irregularity is resulting wate or not
            let isIrrResultingWaste = false;
            for(let j = 0; j<existingIrregularities.length;j++){
              if(existingIrregularities[j].Id_Irregularity === addedIrregularities[i].Id_Irregularity){
                isIrrResultingWaste = existingIrregularities[j].resultingWaste === 1;
                break;
              }
            }
            if(addedIrregularities[i].Id_Irregularity === obj.Id_Irregularity){
              return false;
            }
            /*if(isIrrResultingWaste === isResultingWaste){
              return false;
            }*/
          }
        }
      }


    }

    return true;
  }

  const clickedIrregularityIndex = useCallback(
    (index: number) => {
      setLatestClickedIrregularityIndex(index);
      let obj =
        currentActionScreenManager?.currentActionConfig?.irregularitysList[
          index
        ];

      let newAddingIrregularityData = {
        Id_Irregularity: obj.Id_Irregularity,
        temp_name: obj.name,
        temp_code: obj.code,
        filledIrregularityData: { quantity: "" },
        fullIrregularityObject: obj,
      };
      let irregularityData: any = {
        ...newAddingIrregularityData,
      };

      if (obj?.resultingWaste === 1) {
        setAddingIrregularityData(newAddingIrregularityData);
      }
      if (obj?.occursOnlyOnce === 1) {
        //check if not already added
        let currentAddedIrregularitys = screenState.addedIrregularitys;
        for (let i = 0; i < currentAddedIrregularitys.length; i++) {
          if (
            currentAddedIrregularitys[i].Id_Irregularity === obj.Id_Irregularity
          ) {
            return;
          }
        }

        irregularityData.filledIrregularityData.quantity = 1;
        handleStoreResult(1, irregularityData);
      } else {
        
        setAddingIrregularityData({
          Id_Irregularity: obj.Id_Irregularity,
          temp_name:obj.name,
          temp_code:obj.code,
          filledIrregularityData:{quantity:""},
          fullIrregularityObject:obj
        });
        setRemountKeypadIndex(remountKeypadIndex + 1);
        setCurrentStep("CONFIRM_QTY");
      }
    },
    [
      screenState.addedIrregularitys,
      handleStoreResult,
      remountKeypadIndex,
      currentActionScreenManager?.currentActionConfig?.irregularitysList,
    ]
  );

  function stepConfirm(res: any) {
    if (currentStep === "CONFIRM_QTY") {
      let obj =
        currentActionScreenManager?.currentActionConfig?.irregularitysList[
          latestClickedIrregularityIndex
        ];
      let addingIrregularityData = {
        Id_Irregularity: obj.Id_Irregularity,
        temp_name: obj.name,
        temp_code: obj.code,
        filledIrregularityData: { quantity: "" },
      };
      let irregularityData: any = {
        ...addingIrregularityData,
        fullIrregularityObject: obj,
      };
      
      if (irregularityData.fullIrregularityObject.resultingWaste === 1) {
        handleStoreResult(res, irregularityData);
      } else {
        handleStoreResult(res);
      }
    }
  }

  useEffect(() => {
    if (!didMount) {
      //mounting

      if (hasOnlyOneIrregularity()) {
        //clickedIrregularityIndex(0);
      }

      setIsLoading(false);
      setDidMount(true);
    }
  }, [
    didMount,
    currentActionScreenManager?.currentActionConfig?.irregularitysList,
    clickedIrregularityIndex,
    hasOnlyOneIrregularity,
  ]);

  useEffect(() => {
    return () => {
      //unmounting
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <></>;
  }

  console.log("SSTAT:", screenState);
  if (currentStep === "SELECT_IRREGULARITY") {
    return (
      <>
        <div key={indexkey + "_forceRerender"} className={styles.container}>
          <ActionScreenTopBar
            callbackOnClose={props.callbackOnClose}
            title={t("add.irregularity")}
          />

          <div className={styles.contentIrregularitysSelect}>
            {t("irregularity.production.process")}
            <div className={styles.IrregularitysSelectionOuter}>
              <div className={styles.IrregularitysSelectionContainer}>
                {currentActionScreenManager?.currentActionConfig?.irregularitysList.map(
                  (obj: any, index: number) => (
                    <div key={"Irregularity_" + index}>
                      <ActionScreenIrregularityElement
                        isEnabled={checkIfIsEnabled(obj,index)}
                        name={obj.name}
                        code={obj.code}
                        onClick={() => {
                          clickedIrregularityIndex(index);
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={{position:'relative',top:'-16px'}}>
            {screenState?.addedIrregularitys?.length > 0 && (
              <div className={styles.lineBreaker}></div>
            )}

            <div className={styles.addedIrregularitys}>
              {screenState.addedIrregularitys.map((obj: any, index: number) => (
                <div
                  className={styles.addedIrregularity}
                  key={"addedIrregularity_" + index}
                >
                  <div className={styles.IrregularityName}>{obj.temp_code}</div>
                  <div className={styles.IrregularityRemoveBtnContainer}>
                    <IonButton
                      color="danger"
                      className={styles.IrregularityRemoveBtn}
                      onClick={() => {
                        let currentAddedIrregularitys =
                          screenState.addedIrregularitys;
                        let newAddedIrregularitys = [];
                        for (let i = 0; i < currentAddedIrregularitys.length; i++) {
                          if (i !== index) {
                            newAddedIrregularitys.push(
                              currentAddedIrregularitys[i]
                            );
                          }
                        }
                     
                        dispatchActionScreenAskProducedProductIrregularityStore({
                          type: "SET_ADDED_IRREGULARITIES",
                          payload: newAddedIrregularitys,
                        });
                      }}
                    >
                      <IonIcon slot="icon-only" size="large" icon={closeOutline} />
                    </IonButton>
                  </div>
                </div>
              ))}
            </div>

            {screenState.addedIrregularitys.length > 0 ? (
              <ActionScreenAskProducedProductIrregularityConfirmationButtons
                typeShow={"CONFIRM"}
                onClickConfirm={() => {
                  confirmClick();
                }}
              />
            ) : (
              <ActionScreenAskProducedProductIrregularityConfirmationButtons
                typeShow={"NO_IRREGULARITYS"}
                onClickConfirm={() => {
                  let storeResult = confirmIrregularitysList();
                  props.callbackSuccessConfirm(storeResult);
                }}
              />
            )}
        </div>

      </>
    );
  }

  if (currentStep === "DEFINE_WASTES") {
    return (
      <>
        <div key={indexkey + "_forceRerender"} className={styles.container}>
          <ActionScreenTopBar
            closeOverride={() => {
              setCurrentStep("SELECT_IRREGULARITY");
            }}
            title={t('product.wastes')}
          />
          <div className={styles.properSubtitleWastes}>
            <div className={styles.iconTypeCons}><DeleteIcon fontSize="medium"/></div>
              <div>{t('product.wastes')}</div>

          </div>
          <div className={styles.content}>
            <ActionScreenIrregularityDefineWastes
              fullIrregularityObject={addingIrregularityData.fullIrregularityObject}
              mapWastes={getCurrentMapWastes()}
              arrayProductsData={[]}
              attachBomProducts={true}
              onModifyWastes={(wastesProducts:IWasteProduct[]) => {

                if(!wastesProducts){
                  wastesProducts = [];
                }
                let currentMap: IMapWastes | any = null;
                if (addingIrregularityData.filledIrregularityData?.mapWastes) {
                  currentMap =
                    addingIrregularityData.filledIrregularityData?.mapWastes;
                } else {
                  currentMap = {};
                }

                for(let i = 0; i<wastesProducts.length; i++){
                  let wasteProduct = wastesProducts[i];
                  let mapkey = buildWasteKey(wasteProduct);
            
       
  
                  let currentProductWasteData: IWasteProduct | any = null;
                  if (currentMap[mapkey]) {
                    currentProductWasteData = currentMap[mapkey];
                  }
  
              
                  if (currentMap[mapkey]) {
                    currentProductWasteData = wasteProduct;
                  }
  
  
                  currentMap[mapkey] = currentProductWasteData;
                }
              
               
                setAddingIrregularityData({
                  ...addingIrregularityData,
                  filledIrregularityData: {
                    ...addingIrregularityData.filledIrregularityData,
                    mapWastes: JSON.parse(JSON.stringify(currentMap)),
                  },
                });
              }}
              onModifyWasteLocation={(wasteProduct: IWasteProduct, newLocationData:any) => {
                let mapkey = buildWasteKey(wasteProduct);
                let currentMap: IMapWastes | any = null;
                if (addingIrregularityData.filledIrregularityData?.mapWastes) {
                  currentMap =
                    addingIrregularityData.filledIrregularityData?.mapWastes;
                } else {
                  currentMap = {};
                }

                let currentProductWasteData: IWasteProduct | any = null;
                if (currentMap[mapkey]) {
                  currentProductWasteData = currentMap[mapkey];
                }

                if (newLocationData === null) {
                  newLocationData = null;
                } else {
                  
                }

                if (currentMap[mapkey]) {
                  currentProductWasteData.locationData = newLocationData;
                }

                if (!currentProductWasteData) {
                  let newWasteProduct: IWasteProduct = wasteProduct;
                  wasteProduct.locationData = newLocationData;
                  currentProductWasteData = newWasteProduct;
                }

                currentMap[mapkey] = currentProductWasteData;
               
                setAddingIrregularityData({
                  ...addingIrregularityData,
                  filledIrregularityData: {
                    ...addingIrregularityData.filledIrregularityData,
                    mapWastes: currentMap,
                  },
                });
              }}
              onModifyWastesQty={(wasteProduct: IWasteProduct, newQty: any) => {
                let mapkey = buildWasteKey(wasteProduct);
                let currentMap: IMapWastes | any = null;
                if (addingIrregularityData.filledIrregularityData?.mapWastes) {
                  currentMap =
                    addingIrregularityData.filledIrregularityData?.mapWastes;
                } else {
                  currentMap = {};
                }

                let currentProductWasteData: IWasteProduct | any = null;
                if (currentMap[mapkey]) {
                  currentProductWasteData = currentMap[mapkey];
                }

                if (newQty === "") {
                  newQty = 0;
                } else {
                  let containsOnlyNumbersAndDotRgx = /^[0-9]*\.?[0-9]*$/;

                  if (!String(newQty).match(containsOnlyNumbersAndDotRgx)) {
                    newQty = currentProductWasteData.Quantity; //keeps previous qty
                  }
                }

                if (currentMap[mapkey]) {
                  currentProductWasteData.Quantity = newQty;
                }

                if (!currentProductWasteData) {
                  let newWasteProduct: IWasteProduct = wasteProduct;
                  wasteProduct.Quantity = newQty;
                  currentProductWasteData = newWasteProduct;
                }

                currentMap[mapkey] = currentProductWasteData;
               
                setAddingIrregularityData({
                  ...addingIrregularityData,
                  filledIrregularityData: {
                    ...addingIrregularityData.filledIrregularityData,
                    mapWastes: currentMap,
                  },
                });
              }}
            />
          </div>
        </div>
        <SubScreenDefineWastesConfirmationButtons
          onClickConfirm={() => {
            let addingIrregularityDataObject = { ...addingIrregularityData };
            //format .qty and qty.
            confirmWastesDialog(addingIrregularityDataObject);
          }}
        />
      </>
    );
  }
 
  console.log("IRR data:",addingIrregularityData);
  if (currentStep === "CONFIRM_QTY") {
    return (
      <>
        <div key={indexkey + "_forceRerender"} className={styles.container}>
          <ActionScreenTopBar
            closeOverride={() => {
              setCurrentStep("SELECT_IRREGULARITY");
            }}
            title={t('irregularity.occurrences.qty')}
          />

          <div className={styles.contentQtyOcurrences}>
            <div className={styles.label}>
             
            
             <Trans i18nKey="type.amount.of.occurrences" values={{occurrenceName:addingIrregularityData.temp_name,productName:productionOrderSelectionStore.product.name}}>
              Type in the amount of <b>&quot;{addingIrregularityData.temp_name}&quot;</b> occurences on the product <b>&quot;{productionOrderSelectionStore.product.name}&quot;</b>
             </Trans>
            </div>
            <div
              className={styles.keyPad}
              key={"produceQtyKeyPad_" + remountKeypadIndex}
            >
              <HxfDigitKeyboard
         
                defaultValue={""}
                onChange={(res) => {
                  console.log("CHANGING::: ", res);
                  setShowErrorInput(false);
                }}
                showErrorInput={showErrorInput}
                onConfirmation={(res) => {
                  let willAddIrregularity = true;
                  if (res === "" || res === 0) {
                    willAddIrregularity = false;
                  } else if (res < 0) {
                    setShowErrorInput(true);
                    return;
                  }
                  let currentIrr =  currentActionScreenManager?.currentActionConfig?.irregularitysList[
                    latestClickedIrregularityIndex
                  ];
               

                  if(currentIrr?.reducesOperationsCount === 1){
                  
                    let definedProductivityScale = productivityScale.getProductivityScale();
                    if(definedProductivityScale < res){
                      setShowErrorInput(true);
              
                      return;
                    }
                  }

                
                  if(currentIrr?.limitToWorkingQty === 1){

                    let maxAllowedOccurrences = getMaximumOccurrencesAllowedLimitToWorkingQty();
                    if(res > maxAllowedOccurrences){
                      setShowErrorInput(true);
                      return;
                    }
                  }
            
                  if (willAddIrregularity) {
                    //todo add Irregularity
                  }

                  stepConfirm(res);

                  
                  if (hasOnlyOneIrregularity() && addingIrregularityData?.resultingWaste_calculateFromBomAndOccurrences_noConfirmation === 1) {
                    confirmClick();
                  }
                }}
                enableButtonOnMount={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return <></>;
}
export default ActionScreenAskProducedProductIrregularity;
